.main-container {
    height: 100vh;
    .ant-layout-sider {
        transition: all ease 0.3s;
    }
    .ant-layout-sider-collapsed {
        .ant-layout-sider-children {
            position: fixed;
            background: #001529;
            width: 80px;
        }
        .ant-menu-inline-collapsed {
            .ant-menu-item {
                padding: 0 calc(50% - 16px / 2) !important;
            }
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;

            height: 32px;
            margin: 16px;
            background: #333;
            border-radius: 6px;
            white-space: nowrap;
        }
    }
    .ant-layout-sider-children {
        position: fixed;
        background: #001529;
        width: 200px;
        transition: all ease 0.3s;
        user-select: none;
        &:hover {
            overflow-y: auto;
            &::-webkit-scrollbar {
                position: absolute;
                width: 8px;
                // height: 8px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background: linear-gradient(to left, #6d6b6b, #a09d9d);
                border: 1px solid #dddddd;
            }
        }
    }
    .logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        height: 32px;
        margin: 16px;
        background: #333;
        border-radius: 6px;
        white-space: nowrap;
        transition: all ease 0.3s;
    }
    .trigger {
        font-size: 16px;
        cursor: pointer;
        margin-left: 10px;
        transition: color 0.3s;
        color: #fff;
        &:hover {
            color: #1890ff;
        }
    }
    .ant-layout-logo {
        text-align: center;
        line-height: 32px;
        color: #ccc;
        margin-left: 12px;
    }
    .site-layout-background {
        overflow-y: auto;
        margin: 8px 8px 8px 8px;
        background: white;
        padding: 15px 16px 15px 16px;
        // overflow-y: auto;
        // &::-webkit-scrollbar {
        //     position: absolute;
        //     width: 10px;
        //     // height: 8px;
        // }
        // &::-webkit-scrollbar-thumb {
        //     border-radius: 6px;
        //     background: linear-gradient(to left, #6d6b6b, #a09d9d);
        //     border: 1px solid #dddddd;
        // }
        // &:hover {
        // }
    }
    .layout-header {
        height: auto;
        background: white;
        margin: 0;
        padding: 0;
        .container-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    @media (max-width: 767px) {
        .layout-header {
            .container-header {
                justify-content: center;
                flex-direction: column-reverse;
                .wrap-branch {
                    margin: -5px 0;
                }
            }
        }
    }
    .ant-tabs-bar.ant-tabs-top-bar {
        margin: 25px 0 16px 0 !important;
    }

    .icon-user {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        text-align: center;
        margin-left: 10px;
    }
}
