.context-menu-popup {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    left: 0px;
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: fixed;
    text-align: left;
    top: 0px;
    overflow: hidden;
    z-index: 9;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    li {
        clear: both;
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        margin: 0;
        padding: 5px 12px;
        transition: all 0.3s;
        white-space: nowrap;
        -webkit-transition: all 0.3s;
        &:hover {
            background-color: #e6f7ff;
        }
        & > i {
            margin-right: 8px;
        }
    }
}
