.pn-container-student {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.name-student {
    margin-left: 10px;
    font-weight: 700;
    font-size: 16px;
}

.name-breadcrumb {
    font-size: 15px;
    font-weight: 500;
}
.is-link {
    color: #1890ff !important;
}

.breadcrumb {
    padding: 5px 0 !important;
    margin: -17px -16px 0 -16px !important;
    // margin-bottom: 10px !important;
    background: #f0f2f5 !important;
}
