.student-dashboard-container {
    margin-top: 15px;
    .tab-student {
        &__select-year {
            @media only screen and (min-width: 300px) {
                margin-bottom: 10px;
            }
            @media only screen and (min-width: 600px) {
                margin-right: 10px;
            }
        }
        // &__select-language{
        //     @media only screen and (min-width: 768)  {

        //     }
        // }
    }
}

.card-pane {
    // margin-left: -32px !important;
    // margin-right: -33px !important;
    background: #edf0f3;
    // padding: 0 8px;
    margin-top: -16px;
    padding-bottom: 8px;
    .skeleton-tv {
        .ant-skeleton-title {
            margin-top: 2px !important;
        }
    }
    .ant-col > .ant-card-bordered {
        box-shadow: 0 0 5px #ddd;
        border-radius: 5px;
        .ant-card-body {
            padding: 31px 5px;
        }
    }
    .content-card-info {
        height: 80px;
        text-align: center;
    }
}
@media (max-width: 767px) and (min-width: 576px) {
    .card-pane {
        .content-card-info {
            box-shadow: none !important;
            border-radius: 0 !important;
            border-left: none;
        }
    }
}

@media (max-width: 576px) {
    .card-pane {
        .content-card-info {
            box-shadow: none !important;
            border-radius: 0 !important;
            border-left: none;
            border-right: none;
            border-bottom: none;
            height: 60px;
        }
        .ant-card-body {
            padding: 20px 5px !important;
        }
    }
}
