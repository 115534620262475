@media only screen and (max-width: 991px) {
    .pne-controll-response__button {
        margin-top: 10px;
        width: 100%;
    }
}

.item-card {
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 6px 3px #e7e5e5;
    &__info {
        b {
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-collapse-item {
        border-bottom: none !important;
    }
    .ant-collapse-content-box {
        color: rgba(0, 0, 0, 0.85) !important;
        overflow-x: hidden;
    }
}

.list-item {
    width: 100%;

    .filter-collapse {
        margin-bottom: 10px;
    }
    .item-card {
        padding: 10px;
        border: 1px solid black;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow: 3px 3px 6px 3px #e7e5e5;
        &__info {
            b {
                color: rgba(0, 0, 0, 0.85);
            }
        }
        .ant-collapse-item {
            border-bottom: none !important;
        }
        .ant-collapse-content-box {
            color: rgba(0, 0, 0, 0.85) !important;
            overflow-x: hidden;
        }
    }
    .pagination {
        text-align: center;
        margin: 10px 0;
        li {
            margin-bottom: 10px;
        }
    }
    .filter-select {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

//print
@media screen {
    .noPrint {
        display: block;
        justify-content: center;
        align-items: center;
        // padding: 0 32px;
        box-shadow: 0 0 5px #0000004f;
    }
    // .container-sider{
    //     position: relative;
    // }
    .noPrint::-webkit-scrollbar {
        display: none;
    }
    .noPrint::-webkit-scrollbar-thumb {
        display: none;
        // position: absolute;
        // height: 100%;
        // top: 0;
        // right: 20px;
        // background: #f50;
    }
    .noPrint:hover::-webkit-scrollbar,
    .noPrint:hover::-webkit-scrollbar-thumb {
        display: block;
    }
    .yesPrint {
        left: 200px !important;
    }
}
@media print {
    .noPrint,
    .ant-notification {
        display: none;
    }
    .yesPrint {
        left: 0 !important;
    }
}

.print-view-new {
    font-family: Courier;
    position: relative;
    font-size: 15px;
    width: 210mm;
    height: 140mm;
    margin: 0;
    padding: 5mm 15mm;
    background-color: #f2f2f2;
    .logo {
        img {
            width: 65px;
            height: 65px;
            position: absolute;
            right: 40px;
            top: 5px;
        }
        p {
            position: absolute;
            right: 0;
            top: 70px;
            font-size: 13px;
            word-spacing: -4px;
        }
    }
    .print-title {
        text-align: center;
        padding: 10px 0;
        h3 {
            font-weight: bold;
            margin-bottom: 0;
        }
    }
    .print-row {
        display: flex;
        // margin-top: 6px;
        margin-bottom: 0;
        span {
            flex: 1;
        }
        span.flex1 {
            flex: 1;
        }
        span.flex2 {
            flex: 2;
        }
        .text-right {
            text-align: right;
        }
        &.full-row {
            display: block;
            span {
                display: block;
            }
        }
        &.dedive-2 {
            justify-content: space-between;
            span {
                display: block;
            }
        }
        &.hidden-print {
            .text-over-content {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
    .dedive-money {
        display: flex;
        margin-bottom: 0;
        justify-content: space-between;
        margin-right: 128px;
        .print-cell-2 {
            padding-right: 0;
            min-width: 128px;
            text-align: right;
        }
        &.hidden-print {
            .text-over-content {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .print-signature {
        display: flex;
        justify-content: space-between;
        padding: 6px 0;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .fullname {
                margin-top: 60px;
            }
        }
    }

    .print-cell-60 {
        flex: 0 0 60%;
        max-width: 60%;
    }
    .print-cell-40 {
        flex: 0 0 40%;
        max-width: 40%;
    }
    .date-signature {
        position: absolute;
        left: 15mm;
        right: 15mm;
        top: 85mm;
    }
}

@media print {
    html,
    body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
        .ant-notification {
            display: none;
        }
    }
    * {
        overflow: hidden;
    }

    @page {
        size: 210mm 140mm;
        margin: 0;
        background-color: transparent;
    }

    .layout-content {
        margin: 0 !important;
        padding: 0 !important;
        .print-view-new {
            padding: 0.5cm 1.5cm 0.5cm 1.5cm;
            margin: 0;
            width: 21cm;
            .print-row {
                .print-cell-60 {
                    flex: 0 0 60%;
                    max-width: 60%;
                }
                .print-cell-40 {
                    flex: 0 0 40%;
                    max-width: 40%;
                }
                .print-cell-70 {
                    flex: 0 70%;
                    max-width: 70%;
                }
                .print-cell-30 {
                    flex: 0 30%;
                    max-width: 30%;
                }
            }
        }
    }
}
