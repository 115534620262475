.bg-login {
    background-color: #ececec;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.login-form {
    background-color: white;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    border-radius: 10px;
}

.btn-login {
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .login-form {
        width: 100%;
    }
    .col-width {
        width: 70%;
    }
}

@media only screen and (min-width: 501px) and (max-width: 1365px) {
    .login-form {
        width: 60%;
    }
    .col-width {
        width: 70%;
    }
}

@media only screen and (min-width: 1366px) {
    .login-form {
        width: 25%;
    }
    .col-width {
        width: 60%;
    }
}
